<template>
  <div>
    <div class="recommend_box">
      <div class="top_box">
        <div class="title">版面剖析</div>
        <div class="img_box"><img src="@/assets/page/tuijian.png" alt="" /></div>
        <div class="jiangxin">匠心之作，追求品质的极致</div>
      </div>
      <div class="buttom_box flex-space-between" v-if="succession">
        <div class="left">
          <div v-for="(item, index) in succession" :key="index" class="List" @click="itemclick(index)">
            <div class="name" :style="activeindex == index ? 'color:#000' : ''">
              <i>{{ index + 1 }}.</i>{{ item.name }}
            </div>
            <div class="explains" :style="activeindex == index ? 'color:#000' : ''">{{ item.en_name }}</div>
          </div>
        </div>
        <div class="right" @click="imgclick">
          <img
            :src="succession[activeindex].image"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    succession: {

    }
  },
  watch: {
    succession: {
      handler(nav) {
        this.succession=nav
      }
    }
  },
  data() {
    return {
      Listarr: ['基材', '装饰纸', '钢板压纹', '专属防伪'],
      activeindex: 0 //高亮
    }
  },
  components: {},
  mounted() {},
  methods: {
    itemclick(index) {
      this.activeindex = index
    },
    imgclick(){
      
    }
  }
}
</script>
<style scoped lang="less">
.recommend_box {
  background-color: #e9eef1;
  position: relative;
  .top_box {
    padding-top: 97px;
    margin: 0px 85px;
    .title {
      color: #000000;
      font-size: 24px;
    }
    .img_box {
      margin-top: 14px;
      width: 559px;
      height: 45px;
      img {
        width: 559px;
        height: 45px;
      }
    }
    .jiangxin {
      display: flex;
      justify-content: flex-end;
      margin-top: 44px;
      color: #666666;
      font-size: 18px;
    }
  }
  .buttom_box {
    margin: 38px 0px 0 85px;
    overflow: hidden;
    .left {
      .List {
        cursor: pointer;
        margin-top: 107px;
        .name {
          font-size: 34px;
          color: #999999;
          i {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .explains {
          margin-left: 26px;
          color: #999999;
          font-size: 16px;
        }
      }
      .List:nth-child(1) {
        margin-top: 45px;
      }
      .activeclass {
        color: #000000;
      }
    }
    .right {
      // cursor: pointer;
      overflow: hidden;
      transition: all 0.6s;
      img {
        width: 1440px;
        height: 700px;
        transition: all 0.6s;
      }
    }
    /* .right img:hover{
      transition: all 0.6s;
      transform: scale(1.2);
    } */
  }
}
</style>
