<template>
  <div>
    <div class="Coverimg">
      <img :src="pagedatas.banner" alt="" />
    </div>
    <div class="textbox" >
      <div class="text1" > {{page.memo}}</div>
      <div class="text2" >{{page.en_memo}}</div>
    </div>
    <!-- 版面剖析 -->
    <analysis :succession="pagedatas.face"></analysis>
    <!-- 板材系列 -->
    <sheetseries :grain="pagedatas.series" :panel="pagedatas.panel"></sheetseries>
    <!-- 滚动 -->
    <roll :rolldata="pagedatas.certs"></roll>
  </div>
</template>

<script>
import analysis from '@/components/component/analysis.vue'
import sheetseries from '@/components/component/sheetseries.vue'
import roll from '@/components/component/roll.vue'
import {paneldata} from '../../service/request/homepage'
export default {
  data() {
    return {
      page:{},
      pagedatas:{}
    }
  },
  components: {
    analysis,
    sheetseries,
    roll
  },
  // watch: {
  //   pagedatas: {
  //     handler(nav) {
  //       this.pagedatas = nav
  //       console.log(nav);
  //     }
  //   }
  // },
  mounted() {
    this.initdata()
  },
  methods: {
    // 初始数据
    initdata() {
      paneldata().then(res => {
        // console.log('1', res);
        this.pagedatas = res.msg
        this.page=res.msg.panel
      })
    }
  }
}
</script>
<style scoped lang="less">
.Coverimg {
  width: 100%;
  height: 1080px;
  img {
    width: 100%;
    height: 1080px;
  }
}
.textbox {
  margin: 0 85px;
  background-color: #fff;
  .text1 {
    margin: 90px 0 50px;
    color: #666666;
    font-size: 16px;
  }
  .text2 {
    color: #666666;
    font-size: 16px;
    margin-bottom: 94px;
  }
}
</style>
