<template>
  <div>
    <div class="recommend_box">
      <div class="top_box">
        <div class="title">版面剖析</div>
        <div class="img_box"><img src="@/assets/page/tuijian.png" alt="" /></div>
        <div class="textone">环保板材荣获某某机构认证并通过某某机构多项检测</div>
      </div>
      <div class="buttombox">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide itemclass" v-for="(item, index) in rolldata" :key="index">
              <img
                class="imageox"
                :src="item.image"
                alt=""
              />
              <div class="text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
export default {
  props: {
    rolldata: {
      
    }
  },
  data() {
    return {
      isloop: false,
      rollnum:[],
    }
  },
  components: {},
  watch: {
    rolldata: {
      handler(nav) {
        this.rolldata = nav
        if (this.rolldata.length < 0) {
          this.isloop=false
        } else {
          this.isloop=true
        }
      }
    }
  },
  mounted() {
    
    new Swiper('.swiper-container', {
      // loop: true,
      // 如果需要分页器
      slidesPerView: 'auto',
      loop: this.isloop,
      autoplay: 1090,
      pagination: '.swiper-pagination',
      autoplayDisableOnInteraction : false,
      // 如果需要前进后退按钮
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      grabCursor: true

      // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    })
  }
}
</script>
<style scoped lang="less">
// @import 'swiper/dist/css/swiper.css';
// @import "../../../node_modules/swiper/dist/css/swiper.css";
// @import '../../../node_modules/swiper/dist/';
.recommend_box {
  width: 100%;
  height: 668px;
  background: url('../../assets/page/bjt3.png') no-repeat no-repeat;
  background-size: cover;
  position: relative;
  .top_box {
    padding-top: 97px;
    margin: 0px 85px;
    .title {
      color: #000000;
      font-size: 24px;
    }
    .img_box {
      margin-top: 14px;
      width: 559px;
      height: 45px;
      img {
        width: 559px;
        height: 45px;
      }
    }
    .textone {
      margin-top: 26px;
      font-size: 16px;
      color: #333333;
    }
  }
  .buttombox {
    margin: 55px 85px 0;

    .swiper-slide {
      height: 338px;
      background-color: #bfa;
      width: 240px; /*根据内容调整宽度*/
      margin-right: 30px;
    }
    .swiper-scrollbar {
      margin-top: 30px;
    }
    .itemclass{
      background-color: #fff;
      height: 338px;
    }
    .text{
      color: #666666;
      font-size: 14px;
      text-align: center;
      margin-top: 30px;
      font-family: Source Han Sans CN;
    }
    .imageox {
      height: 270px;
      width: 210px;
      margin: 7px 14px 0;
    }
  }
}
</style>
