<template>
  <div>
    <div class="recommend_box">
      <div class="top_box">
        <div class="title">纹理维度甄选更直观</div>
        <div class="img_box"><img src="@/assets/page/tuijian.png" alt="" /></div>
        <div class="Listbox" v-if="grain">
          <div class="itembox" v-for="(item, index) in grain" :key="index" @click="itemclick(item.id)">
            <img :src="item.image" alt="" />
            <div class="textad">
              <div>{{ item.name }}</div>
              <div style="font-size: 14px; margin-top: 10px">{{ item.en_name }}</div>
            </div>
          </div>
          <div class="introduce">
            <div style="margin: 57px 36px 0; color: #fff;">{{ panel.brief }}</div>
            <div style="margin: 36px 36px;color: #fff;">{{ panel.en_brief }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    grain: {
      
    },
    panel:{}
  },
  data() {
    return {

    }
  },
  watch: {
    grain: {
      handler(nav) {
        this.grain=nav
      }
    },
    panel: {
      handler(nav) {
        this.panel=nav
      }
    },
    
  },
  components: {},
  mounted() {},
  methods: {
    itemclick(id) {
      console.log(id);
      this.$router.push({
        path: '/boarditem',
        query: {
          id
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.recommend_box {
  background-color: #fff;
  position: relative;
  .top_box {
    padding-top: 97px;
    margin: 0px 85px;
    .title {
      color: #000000;
      font-size: 24px;
    }
    .img_box {
      margin-top: 14px;
      width: 559px;
      height: 45px;
      img {
        width: 559px;
        height: 45px;
      }
    }
    .Listbox {
      margin-top: 60px;
      margin-bottom: 84px;
      display: flex;
      flex-wrap: wrap;
      .itembox {
        cursor: pointer;
        transition: 0.3s ease-in;
        width: 856px;
        height: 270px;
        margin-right: 20px;
        margin-top: 20px;
        position: relative;
        img {
          width: 856px;
          height: 270px;
        }
        .textad {
          position: absolute;
          top: 38px;
          left: 36px;
          color: #ffffff;
          font-size: 24px;
        }
      }
      .itembox:hover{
        transition: 0.3s ease-in;
        transform: translateY(-10px);
      }
      .introduce {
        width: 856px;
        transition: 0.3s ease-in;
        height: 270px;
        margin-top: 20px;
        background-color: #3e907c;
      }
      .introduce:hover{
        transition: 0.3s ease-in;
        transform: translateY(-10px);
      }
      .itembox:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
